@import "minima";

.site-footer {
  
    a, a:hover, a:focus, a:active {
      text-decoration: none !important;
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none !important;
    }

    .social-links {
      display: flex;
      align-items: center;

      a, a:hover, a:focus, a:active { 
        text-decoration: none !important;
        border-bottom: none !important;
        box-shadow: none !important;
      }
    }

    .social-icon {
      margin-left: 10px;
      text-decoration: none !important;
      
      .svg-icon {
        width: 20px;
        height: 20px;
        fill: #828282;
        transition: fill 0.3s ease;
      }

      &:hover {
        text-decoration: none !important;

        .svg-icon {
          fill: #2a7ae2;
        }
      }
      a, a:hover, a:focus, a:active { 
        text-decoration: none !important;
      }
}
}

.header-image {
  width: 100%;
  height: 300px; // Adjust as needed
  object-fit: cover;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
.site-header {
  .wrapper {
  flex-direction: column;
  align-items: flex-start;
  }

  .social-links {
  margin-top: 10px;

  &:hover {
      text-decoration: none !important;
  }
  }
}
}